import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import CloseButton from "../images/close.svg";

import "../styles/FreeGemsPopup.css";

const Images = graphql`
  query {
    image1: file(relativePath: { eq: "free-gems-popup/free-gems-popup.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image2: file(
      relativePath: { eq: "free-gems-popup/free-gems-popup-install.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 650) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

class FreeGemsPopup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      minutes: "01",
      seconds: "30",
      downloadsCount: 3598,
    };
  }

  componentDidMount() {
    // start timer for 1.5minutes
    this.startTimer(60 * 1.5);
  }

  componentWillUnmount() {
    clearInterval(this.state.countdownTimer);
  }

  startTimer = (duration) => {
    let timer = duration,
      minutes,
      seconds;

    const countdownTimer = setInterval(() => {
      minutes = parseInt(timer / 60, 10);
      seconds = parseInt(timer % 60, 10);

      this.setState({
        minutes: minutes < 10 ? "0" + minutes : minutes,
        seconds: seconds < 10 ? "0" + seconds : seconds,
        downloadsCount:
          this.state.downloadsCount + (seconds % 10 === 0 ? 1 : 0),
      });

      if (--timer < 0) {
        this.props.closeFreeGemsPopup();
      }
    }, 1000);
    this.setState({ countdownTimer });
  };

  render() {
    const { closeFreeGemsPopup, installUrl } = this.props;
    const { minutes, seconds, downloadsCount } = this.state;

    return (
      <div id="free-gems-backdrop">
        <div className="bg">
          <div id="free-gems-popup">
            <div className="close">
              <div onClick={closeFreeGemsPopup}>
                <img
                  src={CloseButton}
                  alt="close button"
                  style={{ width: "100%" }}
                />
              </div>
            </div>
            <span
              style={{
                fontSize: "15px",
                // fontWeight: "600"
                // marginTop: "10px",
                textAlign: "center",
              }}
            >
              Riya won ₹89,580 1<sup>st</sup> week mein!
            </span>
            <span style={{}} className="subtext">
              Play high stakes poker to win bahut jyada cash. Next tournament
              starts in:
            </span>
            <div className="timer">
              <span>00:</span>
              {minutes}
              <span>:</span>
              {seconds}
            </div>
            <StaticQuery
              query={Images}
              render={(data) => {
                let fluidImgFromQuery = [];
                for (let key in data) {
                  fluidImgFromQuery.push(data[key].childImageSharp.fluid);
                }

                return (
                  <div
                    style={{
                      width: "212px",
                    }}
                  >
                    <div>
                      <Img fluid={fluidImgFromQuery[0]} alt="" />
                    </div>
                    <div>
                      <a
                        onClick={() => {
                          closeFreeGemsPopup();
                          this.props.fbEventAndShowModal(
                            "Popup Install Button"
                          );
                        }}
                        href={installUrl}
                      >
                        <Img
                          fluid={fluidImgFromQuery[1]}
                          alt=""
                          imgStyle={{ objectFit: "contain" }}
                        />
                      </a>
                    </div>
                  </div>
                );
              }}
            />
            <span
              style={{
                marginTop: "16px",
                fontFamily: '"Gotham Rounded Book" !important',
              }}
              className="downloads"
            >
              Today's downloads : {downloadsCount}
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default FreeGemsPopup;
