import React from "react"


import Layout from "../components_landing/layout"
import SEO from "../components_landing/seo"
import App from '../components_landing/App';
import '../styles/landing.css';

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <App />
  </Layout>
)

export default IndexPage
