import React from "react";
import ReviewsList from "./ReviewsList";
import icon from "../images/icon.svg";

class Description extends React.Component {
  state = {
    listItems: [
      "Compete head to head against verified gamers and show them who is the boss",
      "100% Safe & Secure",
      "Smooth Deposits",
      "Fastest Withdrawals",
    ],
    moreItems: [
      "Efficient Customer Support",
      "Only Verified Profiles",
      "Play Addictive Card Games",
      "Start playing epic games on Mega, and keep winning REAL cash",
    ],
    itemsToShow: [
      "Compete head to head against verified gamers and show them who is the boss",
      "100% Safe & Secure",
      "Smooth Deposits",
      "Fastest Withdrawals",
    ],
    showingMoreItems: false,
    scrollHeight: 0,
    initialMount: true,
    progress: 0,
  };
  handleListItems = () => {
    let { showingMoreItems, listItems, moreItems } = this.state;
    let totalItems;
    if (!showingMoreItems) {
      totalItems = [...listItems, ...moreItems];
    } else {
      totalItems = [...listItems];
    }
    this.setState((state) => {
      return {
        showingMoreItems: !state.showingMoreItems,
        itemsToShow: totalItems,
      };
    });
  };
  async componentDidMount() {
    console.log("description component mounted");
    let self = this;
    window.addEventListener("scroll", function() {
      console.log("reviews scroll", window.pageYOffset);
      if (self.state.initialMount && window.pageYOffset > 2) {
        self.setState(
          { scrollHeight: window.pageYOffset, initialMount: false },
          () => {
            console.log("state is set");
          }
        );
      }
      if (window.pageYOffset > 800 && self.state.progress !== 800) {
        console.log("setting progress", window.pageYOffset);
        self.setState({ progress: 800 });
      }
      if (window.pageYOffset < 800 && self.state.progress !== 0) {
        console.log("unsetting progress");
        self.setState({ progress: 0 });
      }
    });
  }
  render() {
    let { showingMoreItems, itemsToShow, scrollHeight, progress } = this.state;
    let { reviewsVisible, installUrl } = this.props;
    let transitionClass = progress >= 800 ? "fade-in" : "fade-out";
    let text = showingMoreItems ? "COLLAPSE" : "READ MORE";

    return (
      <>
        <div className="description-list">
          <ul>
            {itemsToShow.map((item, i) => {
              return <li key={i}>{item}</li>;
            })}
          </ul>
          <p onClick={this.handleListItems}>{text}</p>
        </div>
        <>
          {" "}
          <ReviewsList
            reviewsVisible={reviewsVisible}
            scrollHeight={scrollHeight}
            data={this.props.data}
          />
          <div className={`sticky-container ${transitionClass}`}>
            <div id="glossy-button" className={`download`}>
              <a
                onClick={() =>
                  this.props.eventTracker("Sticky download app button")
                }
                href={installUrl}
              >
                <div className="icon-flex">
                  <img src={icon} alt="" />
                </div>
                <div
                  className="download-text font-download"
                  style={{ fontFamily: "'Gotham', sans-serif !important" }}
                >
                  Download App
                </div>
              </a>
              <i></i>
            </div>
          </div>
        </>
      </>
    );
  }
}

export default Description;
