import React, { Component } from "react";
import { Carousel } from "react-responsive-carousel";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const Images = graphql`
  query {
    pic1: file(relativePath: { eq: "poker1.png" }) {
      childImageSharp {
        fluid(maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    pic2: file(relativePath: { eq: "poker2.png" }) {
      childImageSharp {
        fluid(maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    pic3: file(relativePath: { eq: "poker3.png" }) {
      childImageSharp {
        fluid(maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    pic4: file(relativePath: { eq: "poker4.png" }) {
      childImageSharp {
        fluid(maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    pic5: file(relativePath: { eq: "poker5.png" }) {
      childImageSharp {
        fluid(maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    pic6: file(relativePath: { eq: "poker6.png" }) {
      childImageSharp {
        fluid(maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    pic7: file(relativePath: { eq: "poker7.png" }) {
      childImageSharp {
        fluid(maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    pic8: file(relativePath: { eq: "poker8.png" }) {
      childImageSharp {
        fluid(maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    pic9: file(relativePath: { eq: "poker9.png" }) {
      childImageSharp {
        fluid(maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
export default class Carousel2 extends Component {
  render() {
    return (
      <StaticQuery
        query={Images}
        render={(data) => {
          console.log(data);
          return (
            <Carousel
              showThumbs={false}
              showArrows={false}
              showStatus={false}
              infiniteLoop
              autoPlay
            >
              <div className="carousel-img">
                <Img
                  fluid={data.pic1.childImageSharp.fluid}
                  alt=""
                  imgStyle={{ objectFit: "contain" }}
                />
              </div>
              <div className="carousel-img">
                <Img
                  fluid={data.pic2.childImageSharp.fluid}
                  alt=""
                  imgStyle={{ objectFit: "contain" }}
                />
              </div>
              <div className="carousel-img">
                <Img
                  fluid={data.pic3.childImageSharp.fluid}
                  alt=""
                  imgStyle={{ objectFit: "contain" }}
                />
              </div>
              <div className="carousel-img">
                <Img
                  fluid={data.pic4.childImageSharp.fluid}
                  alt=""
                  imgStyle={{ objectFit: "contain" }}
                />
              </div>
              <div className="carousel-img">
                <Img
                  fluid={data.pic5.childImageSharp.fluid}
                  alt=""
                  imgStyle={{ objectFit: "contain" }}
                />
              </div>
              <div className="carousel-img">
                <Img
                  fluid={data.pic6.childImageSharp.fluid}
                  alt=""
                  imgStyle={{ objectFit: "contain" }}
                />
              </div>
              <div className="carousel-img">
                <Img
                  fluid={data.pic7.childImageSharp.fluid}
                  alt=""
                  imgStyle={{ objectFit: "contain" }}
                />
              </div>
              <div className="carousel-img">
                <Img
                  fluid={data.pic8.childImageSharp.fluid}
                  alt=""
                  imgStyle={{ objectFit: "contain" }}
                />
              </div>
              <div className="carousel-img">
                <Img
                  fluid={data.pic9.childImageSharp.fluid}
                  alt=""
                  imgStyle={{ objectFit: "contain" }}
                />
              </div>
            </Carousel>
          );
        }}
      />
    );
  }
}
